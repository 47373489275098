<template>
  <div>
    <p class="mb-6">
      {{
        details.isDebtorRequest
          ? $t('assignDebtors.actionDialogInfoDebtorRequest')
          : $t('assignDebtors.actionDialogInfoInvoice')
      }}
    </p>

    <AssignDebtorsBaseData :details="details" />

    <h5 class="mt-12 mb-n4">{{ $t('assignDebtors.foundDebtors') }}</h5>
    <div
      v-for="debtor in details.debtors"
      :key="debtor.id"
      :class="{
        'assign-debtors__debtor': true,
        'assign-debtors__debtor--selected': selectedDebtorId === debtor.id
      }"
    >
      <div class="assign-debtors__area">
        <div>
          <span class="text-h5">
            {{ debtor.name }}
            <span v-if="selectedDebtorId === debtor.id" class="caption primary--text ml-4">
              <icon-check />
              {{ $t('assignDebtors.debtorSelected') }}
            </span>
          </span>
          <br />
          {{ debtor.address.streetAndHouse }}
          <br />
          {{ debtor.address.zip }} {{ debtor.address.city }}
        </div>

        <v-expansion-panels class="assign-debtors__additional-debtor-data" accordion flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template #default="{ open }">
                {{
                  open
                    ? $t('assignDebtors.hideAdditionalData')
                    : $t('assignDebtors.showAdditionalData')
                }}
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="assign-debtors__row grey-background">
                <div class="assign-debtors__column">
                  <assign-debtors-field
                    label="status"
                    :value="filters.formatDebtorStatus(debtor.status)"
                  />
                  <assign-debtors-field
                    label="assignDebtors.creationDate"
                    :value="filters.formatDate(debtor.creationDate)"
                  />
                  <assign-debtors-field label="sapNumber" :value="debtor.sapNumber" />
                  <assign-debtors-field
                    label="assignDebtors.cspIdentifier"
                    :value="debtor.cspIdentifier || '-'"
                  />
                </div>
                <div class="assign-debtors__column">
                  <assign-debtors-field
                    label="debtors.totalCreditLimit"
                    :value="filters.formatCurrency(debtor.limit.approvedLimit)"
                  />
                  <assign-debtors-field
                    label="debtors.availableLimit"
                    :value="filters.formatCurrency(debtor.limit.availableLimit)"
                  />
                  <assign-debtors-field
                    label="assignDebtors.limitUpdated"
                    :value="filters.formatDate(debtor.limit.limitUpdated)"
                  />
                  <assign-debtors-field
                    label="assignDebtors.limitValidUntil"
                    :value="filters.formatDate(debtor.limit.expirationDate)"
                  />
                </div>
                <div class="assign-debtors__column">
                  <assign-debtors-field
                    label="assignDebtors.invoicesCount"
                    :value="debtor.invoices.uploadedInvoicesCount"
                  />
                  <assign-debtors-field
                    label="assignDebtors.openInvoices"
                    :value="debtor.invoices.openInvoicesCount"
                  />
                  <assign-debtors-field
                    label="assignDebtors.firstInvoiceUploadDate"
                    :value="filters.formatDate(debtor.invoices.firstInvoiceUploadDate)"
                  />
                  <assign-debtors-field
                    label="assignDebtors.lastInvoiceUploadDate"
                    :value="filters.formatDate(debtor.invoices.lastInvoiceUploadDate)"
                  />
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div class="semi-bold mt-8">
          {{ $tc('assignDebtors.activeLocationsCounted', debtor.locations.length) }}
        </div>
        <app-radio-group v-model="currentSelection" :row="false">
          <template #radio>
            <v-radio
              v-for="location in debtor.locations"
              :key="location.id"
              :value="JSON.stringify({ debtorId: debtor.id, locationId: location.id })"
              class="assign-debtors__radio"
            >
              <template #label>
                <div class="assign-debtors__row">
                  <div class="assign-debtors__column assign-debtors__column--wide">
                    <div>
                      <span class="text-h5">{{ location.name }}</span>
                      <br />
                      <template v-if="location.contactPerson">
                        {{ location.contactPerson }}
                        <br />
                      </template>
                      {{ location.address.streetAndHouse }}
                      <br />
                      {{ location.address.zip }} {{ location.address.city }}
                    </div>
                  </div>
                  <div class="assign-debtors__column">
                    <assign-debtors-field
                      label="debtors.customerId"
                      :value="location.customNumber || '-'"
                    />
                    <assign-debtors-field
                      label="assignDebtors.creationDate"
                      :value="filters.formatDate(location.creationDate)"
                    />
                  </div>
                  <div class="assign-debtors__column">
                    <assign-debtors-field label="email" :value="location.email || '-'" />
                    <assign-debtors-field
                      label="debtors.deliveryType"
                      :value="
                        $te(`addresses.deliveryTypes.${location.deliveryType}`)
                          ? $t(`addresses.deliveryTypes.${location.deliveryType}`)
                          : '-'
                      "
                    />
                  </div>
                  <div class="assign-debtors__column">
                    <assign-debtors-field
                      label="assignDebtors.invoicesCount"
                      :value="location.invoices.uploadedInvoicesCount"
                    />
                    <assign-debtors-field
                      label="assignDebtors.openInvoices"
                      :value="location.invoices.openInvoicesCount"
                    />
                  </div>
                  <div class="assign-debtors__column">
                    <assign-debtors-field
                      label="assignDebtors.firstInvoiceUploadDate"
                      :value="filters.formatDate(location.invoices.firstInvoiceUploadDate)"
                    />
                    <assign-debtors-field
                      label="assignDebtors.lastInvoiceUploadDate"
                      :value="filters.formatDate(location.invoices.lastInvoiceUploadDate)"
                    />
                  </div>
                </div>
              </template>
            </v-radio>
            <v-radio
              class="assign-debtors__radio"
              :value="JSON.stringify({ debtorId: debtor.id, locationId: '' })"
            >
              <template #label>
                <span class="text-h5">
                  {{
                    details.isDebtorRequest
                      ? $t('assignDebtors.createNewLocationDebtorRequest')
                      : $t('assignDebtors.createNewLocationInvoice')
                  }}
                </span>
              </template>
            </v-radio>
          </template>
        </app-radio-group>
      </div>
    </div>

    <div
      :class="{
        'assign-debtors__debtor py-4': true,
        'assign-debtors__debtor--selected': selectedDebtorId === ''
      }"
    >
      <app-radio-group v-model="currentSelection" :row="false">
        <template #radio>
          <v-radio
            class="assign-debtors__radio"
            :value="JSON.stringify({ debtorId: '', locationId: '' })"
          >
            <template #label>
              <span class="text-h5">
                {{
                  details.isDebtorRequest
                    ? $t('assignDebtors.createNewDebtorDebtorRequest')
                    : $t('assignDebtors.createNewDebtorInvoice')
                }}
              </span>
            </template>
          </v-radio>
        </template>
      </app-radio-group>
    </div>
  </div>
</template>

<script>
import AssignDebtorsBaseData from '@/modules/Admin/components/AdminActions/AssignDebtors/AssignDebtorsBaseData.vue';
import AssignDebtorsField from '@/modules/Admin/components/AdminActions/AssignDebtors/AssignDebtorsField';
import formatCurrency from '@/helper/filter/formatCurrency';
import formatDate from '@/helper/filter/formatDate';
import formatDebtorStatus from '@/helper/filter/formatDebtorStatus';

export default {
  name: 'AssignDebtorsSelection',

  components: { AssignDebtorsBaseData, AssignDebtorsField },

  props: {
    details: {
      type: Object,
      required: true
    },
    selection: {
      type: Object,
      required: true
    }
  },

  computed: {
    currentSelection: {
      get() {
        return JSON.stringify(this.selection);
      },
      set(value) {
        this.$emit('update:selection', JSON.parse(value));
      }
    },
    selectedDebtorId() {
      return this.selection.debtorId;
    },
    filters() {
      return {
        formatCurrency,
        formatDate,
        formatDebtorStatus
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/modules/Admin/styles/assignDebtors.scss';

.assign-debtors {
  &__additional-debtor-data {
    ::v-deep .v-expansion-panel-header .v-expansion-panel-header__icon {
      margin-left: 16px;

      .v-icon {
        color: var(--c-primary);
      }
    }

    ::v-deep .v-expansion-panel-header {
      min-height: 0;
      background-color: var(--c-grey-background);
      margin-top: 24px;
      padding: 12px;
      justify-content: center;
    }

    ::v-deep .v-expansion-panel-content__wrap {
      min-height: unset;
      padding: 0;
    }
  }

  &__radio {
    align-items: flex-start;
    margin-block: 0 !important;
    padding: 24px 8px;

    .assign-debtors__row {
      padding-block: 0;
      margin-top: 0;
    }

    .v-label {
      align-items: flex-start;

      > * {
        padding-left: 16px;
      }
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--c-border);
    }

    &:hover {
      background: var(--c-grey-background);
    }
  }
}
</style>
