import { mapActions } from 'vuex';
import { NAMESPACE as NAMSPACE_VOUCHERS } from '@/modules/Vouchers/store';

export default {
  data: () => ({
    loading: false,
    loadingFileType: ''
  }),

  methods: {
    ...mapActions(NAMSPACE_VOUCHERS, ['getFile']),

    async openFile(url, type, filename, forceDownload) {
      if (type) {
        this.loadingFileType = type;
      } else {
        this.loading = true;
      }

      const { file, error } = await this.getFile(url);

      if (error) {
        this.loading = false;
        this.loadingFileType = '';
        return;
      }

      this.triggerDownload(file, filename, forceDownload);

      this.loading = false;
      this.loadingFileType = '';
    },

    triggerDownload(file, filename, forceDownload) {
      // Edge
      if (window.navigator.msSaveOrOpenBlob) {
        return window.navigator.msSaveOrOpenBlob(file);
      }

      const link = document.createElement('a');

      if (this.isSafari === false && !forceDownload) {
        link.setAttribute('target', '_blank');
      }

      if (this.isSafari === true || forceDownload) {
        link.setAttribute('download', filename);
      }

      link.setAttribute('href', window.URL.createObjectURL(file));

      document.body.appendChild(link);
      link.click();
    }
  }
};
