<template>
  <div>
    <app-dialog
      v-model="open"
      :disabled="selection.locationId === undefined"
      :fill-actions="false"
      :loading="loading"
      width="100%"
      v-bind="dialogBindings.props"
      v-on="dialogBindings.listeners"
    >
      <app-spinner v-if="loadingDetails" class="my-8" />
      <component
        :is="dialogBindings.component"
        v-else
        :details="details"
        :selection.sync="selection"
      />
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="assignDebtors.successTitle"
      text="assignDebtors.successInfo"
      @submit="onCloseAfterSuccess"
      @close="onCloseAfterSuccess"
    />
  </div>
</template>

<script>
import AssignDebtorsSelection from '@/modules/Admin/components/AdminActions/AssignDebtors/AssignDebtorsSelection';
import AssignDebtorsSummary from '@/modules/Admin/components/AdminActions/AssignDebtors/AssignDebtorsSummary';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import { NAMESPACE } from '@/modules/Admin/store';
import { mapActions } from 'vuex';

export default {
  name: 'AssignDebtorsDialog',

  mixins: [ShowDialogMixin],

  props: {
    entryId: {
      type: String,
      default: undefined
    }
  },

  data: () => ({
    details: {},
    selection: {},
    loadingDetails: false,
    showSummary: false
  }),

  computed: {
    dialogBindings() {
      if (this.showSummary) {
        return {
          component: AssignDebtorsSummary,
          props: {
            useCancelAction: true,
            title: 'assignDebtors.confirmSubmit',
            primaryActionText: 'navigation.send',
            secondaryActionText: 'navigation.goBack'
          },
          listeners: {
            submit: this.onSubmit,
            close: this.onClose,
            cancel: this.onCancel
          }
        };
      }

      return {
        component: AssignDebtorsSelection,
        props: {
          title: 'assignDebtors.title',
          primaryActionText: 'navigation.continue',
          secondaryActionText: 'navigation.cancel'
        },
        listeners: {
          submit: this.onContinue,
          close: this.onClose
        }
      };
    }
  },

  watch: {
    entryId: {
      async handler(value) {
        if (!value) {
          return;
        }

        this.loadingDetails = true;

        const { data, error } = await this.fetchDebtorAssignmentDetails(value);
        this.loadingDetails = false;

        if (error) {
          return;
        }

        this.scrollTo(0);
        this.details = data;
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchDebtorAssignmentDetails', 'submitDebtorAssignment']),

    async onContinue() {
      this.scrollTo(0);
      this.showSummary = true;
    },

    async onSubmit() {
      this.loading = true;

      const { response, error } = await this.submitDebtorAssignment({
        entryId: this.entryId,
        debtorId: this.selection.debtorId,
        locationId: this.selection.locationId
      });

      this.loading = false;
      if (error) {
        this.error = error.response?.data;
        return;
      }

      this.response = response;
      this.submitted = true;
    },

    onSuccess() {
      this.scrollTo(0);
      this.submitted = true;
    },

    onClose() {
      this.reset();
      this.$emit('input', false);
    },

    onCloseAfterSuccess() {
      this.$emit('input', false);
      this.$emit('success');
      this.reset();
    },

    onCancel() {
      this.showSummary = false;
    },

    reset() {
      setTimeout(() => {
        this.showSummary = false;
        this.submitted = false;
        this.selection = {};
      }, 200);
    },

    scrollTo(target) {
      this.$vuetify.goTo(target, {
        duration: 0,
        offset: 0,
        container: '.v-dialog__content .scroll-overflow'
      });
    }
  }
};
</script>
