export default {
  data: () => ({
    downloadingFiles: false,
    selectedItems: [],
    showSelection: false
  }),

  watch: {
    showSelection(value) {
      if (value) {
        return;
      }
      this.selectedItems = [];
    }
  },

  computed: {
    classesTitleLeft() {
      return `${
        this.isMobile ? 'body-1 semi-bold justify-space-between mr-2' : 'text-h5'
      } d-flex flex-column align-baseline`;
    },
    classesTitleRight() {
      return `${this.isMobile ? 'subtitle-1 mt-4' : 'body-1 text-right'}`;
    },
    classesTitleRightValue() {
      return `${this.isMobile ? 'body-1 semi-bold' : 'text-h5'} break-word`;
    },
    sortBy() {
      return (this.getQueryParam(this.queryParam.SORT_BY) || [])[0];
    },
    noItemsSelected() {
      return this.selectedItems.length === 0;
    }
  },

  methods: {
    isSorted(index) {
      return this.sortBy !== undefined && this.sortBy === this.tableHeaders[index].value;
    },

    expandRow(item) {
      if (this.expanded.length > 0 && this.expanded[0].id === item.id) {
        // close already expanded row
        this.expanded.splice(0, 1);
      } else {
        // switch expanded row
        this.expanded.splice(0, 1, item);
      }
    },

    setDetailedParams(identifier) {
      if (identifier === this.$route.params.id) {
        this.$router.push({
          params: { id: undefined, type: undefined },
          query: { ...this.$route.query }
        });
        return;
      }

      this.$router.push({
        params: { id: identifier, type: undefined },
        query: { ...this.$route.query }
      });
    },

    setDetailedSubParams(identifier) {
      if (identifier === this.$route.params.id) {
        this.$router.push({
          params: { id: undefined, type: undefined },
          query: { ...this.$route.query }
        });
        return;
      }

      this.$router.push({
        params: { id: identifier, type: 'sub' },
        query: { ...this.$route.query }
      });
    },

    isShownInDetails(id) {
      return this.$route.params.id === id;
    }
  }
};
