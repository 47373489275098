<template>
  <div>
    <p class="mb-6">
      {{
        details.isDebtorRequest
          ? $t('assignDebtors.confirmAssignmentDebtorRequest')
          : $t('assignDebtors.confirmAssignmentInvoice')
      }}
    </p>

    <AssignDebtorsBaseData :details="details" />

    <div class="assign-debtors__debtor">
      <div class="assign-debtors__area">
        <div class="assign-debtors__row">
          <h5>
            {{ $t('assignDebtors.debtor') }}
          </h5>
          <template v-if="debtor">
            <div class="assign-debtors__column">
              <assign-debtors-field label="address">
                {{ debtor.name }}
                <br />
                {{ debtor.address.streetAndHouse }}
                <br />
                {{ debtor.address.zip }} {{ debtor.address.city }}
              </assign-debtors-field>
            </div>

            <div class="assign-debtors__column">
              <assign-debtors-field
                label="status"
                :value="filters.formatDebtorStatus(debtor.status)"
              />
              <assign-debtors-field
                label="assignDebtors.creationDate"
                :value="filters.formatDate(debtor.creationDate)"
              />
              <assign-debtors-field label="sapNumber" :value="debtor.sapNumber" />
              <assign-debtors-field
                label="assignDebtors.cspIdentifier"
                :value="debtor.cspIdentifier || '-'"
              />
            </div>
            <div class="assign-debtors__column">
              <assign-debtors-field
                label="debtors.totalCreditLimit"
                :value="filters.formatCurrency(debtor.limit.approvedLimit)"
              />
              <assign-debtors-field
                label="debtors.availableLimit"
                :value="filters.formatCurrency(debtor.limit.availableLimit)"
              />
              <assign-debtors-field
                label="assignDebtors.limitUpdated"
                :value="filters.formatDate(debtor.limit.limitUpdated)"
              />
              <assign-debtors-field
                label="assignDebtors.limitValidUntil"
                :value="filters.formatDate(debtor.limit.expirationDate)"
              />
            </div>
            <div class="assign-debtors__column">
              <assign-debtors-field
                label="assignDebtors.invoicesCount"
                :value="debtor.invoices.uploadedInvoicesCount"
              />
              <assign-debtors-field
                label="assignDebtors.openInvoices"
                :value="debtor.invoices.openInvoicesCount"
              />
              <assign-debtors-field
                label="assignDebtors.firstInvoiceUploadDate"
                :value="filters.formatDate(debtor.invoices.firstInvoiceUploadDate)"
              />
              <assign-debtors-field
                label="assignDebtors.lastInvoiceUploadDate"
                :value="filters.formatDate(debtor.invoices.lastInvoiceUploadDate)"
              />
            </div>
          </template>

          <template v-else>
            <div>
              <span class="semi-bold subtitle-1 d-block mb-4">
                {{ $t('assignDebtors.newDebtor') }}:
              </span>

              {{ details.addresses.limitAddress.name }}
              <br />
              {{ details.addresses.limitAddress.street }}
              <br />
              {{ details.addresses.limitAddress.zip }}
              {{ details.addresses.limitAddress.city }}
              <template v-if="details.addresses.limitAddress.email">
                <br />
                {{ $t('email') }}: {{ details.addresses.limitAddress.email }}
              </template>
              <template v-if="details.addresses.limitAddress.phone">
                <br />
                {{ $t('tel') }}: {{ details.addresses.limitAddress.phone }}
              </template>
            </div>
          </template>
        </div>
        <v-divider class="assign-debtors__divider" />

        <div class="assign-debtors__row">
          <h5>
            {{ $t('location') }}
          </h5>

          <template v-if="location">
            <div class="assign-debtors__column assign-debtors__column--wide">
              <assign-debtors-field label="address">
                {{ location.name }}
                <br />
                <template v-if="location.contactPerson">
                  {{ location.contactPerson }}
                  <br />
                </template>
                {{ location.address.streetAndHouse }}
                <br />
                {{ location.address.zip }} {{ location.address.city }}
              </assign-debtors-field>
            </div>
            <div class="assign-debtors__column">
              <assign-debtors-field
                label="debtors.customerId"
                :value="location.customNumber || '-'"
              />
              <assign-debtors-field
                label="assignDebtors.creationDate"
                :value="filters.formatDate(location.creationDate)"
              />
            </div>
            <div class="assign-debtors__column">
              <assign-debtors-field label="email" :value="location.email || '-'" />
              <assign-debtors-field
                label="debtors.deliveryType"
                :value="
                  $te(`addresses.deliveryTypes.${location.deliveryType}`)
                    ? $t(`addresses.deliveryTypes.${location.deliveryType}`)
                    : '-'
                "
              />
            </div>
            <div class="assign-debtors__column">
              <assign-debtors-field
                label="assignDebtors.invoicesCount"
                :value="location.invoices.uploadedInvoicesCount"
              />
              <assign-debtors-field
                label="assignDebtors.openInvoices"
                :value="location.invoices.openInvoicesCount"
              />
            </div>
            <div class="assign-debtors__column">
              <assign-debtors-field
                label="assignDebtors.firstInvoiceUploadDate"
                :value="filters.formatDate(location.invoices.firstInvoiceUploadDate)"
              />
              <assign-debtors-field
                label="assignDebtors.lastInvoiceUploadDate"
                :value="filters.formatDate(location.invoices.lastInvoiceUploadDate)"
              />
            </div>
          </template>

          <template v-else>
            <div>
              <span class="semi-bold subtitle-1 d-block mb-4">
                {{ $t('assignDebtors.newLocation') }}:
              </span>
              {{ details.addresses.normalizedAddress.name }}
              <br />
              {{ details.addresses.normalizedAddress.street }}
              <br />
              {{ details.addresses.normalizedAddress.zip }}
              {{ details.addresses.normalizedAddress.city }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssignDebtorsBaseData from '@/modules/Admin/components/AdminActions/AssignDebtors/AssignDebtorsBaseData.vue';
import AssignDebtorsField from '@/modules/Admin/components/AdminActions/AssignDebtors/AssignDebtorsField';
import formatCurrency from '@/helper/filter/formatCurrency';
import formatDate from '@/helper/filter/formatDate';
import formatDebtorStatus from '@/helper/filter/formatDebtorStatus';

export default {
  name: 'AssignDebtorsSummary',

  components: { AssignDebtorsBaseData, AssignDebtorsField },

  props: {
    details: {
      type: Object,
      required: true
    },
    selection: {
      type: Object,
      required: true
    }
  },

  computed: {
    debtor() {
      return this.details.debtors.find((debtor) => debtor.id === this.selection.debtorId);
    },
    location() {
      return this.debtor?.locations.find((location) => location.id === this.selection.locationId);
    },
    filters() {
      return {
        formatCurrency,
        formatDate,
        formatDebtorStatus
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/modules/Admin/styles/assignDebtors.scss';

.assign-debtors {
  &__debtor {
    padding: 0;
  }

  &__divider {
    margin: 12px;
    border-color: var(--c-border) !important;
  }
}
</style>
