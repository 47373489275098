<template>
  <app-data-table
    :headers="tableHeaders"
    :items="formattedTableData"
    :loading="loadingList"
    :total-data-length="count"
  >
    <template #title>
      <div class="table-title__content">
        <span class="text-h5">{{ $tc('resultsCounted', count) }}</span>
      </div>
    </template>

    <template #item="{ item }">
      <tr>
        <app-table-data :sorted="isSorted(0)" :header="tableHeaders[0]">
          {{ item.statusTranslated }}
        </app-table-data>
        <app-table-data :sorted="isSorted(1)" :header="tableHeaders[1]">
          {{ item.creditor.name }}
          <div class="subtitle-1 grey--text">
            {{ item.productTranslated }}
          </div>
        </app-table-data>
        <app-table-data :sorted="isSorted(2)" :header="tableHeaders[2]">
          {{ item.creditor.sapNumber }}
        </app-table-data>
        <app-table-data :sorted="isSorted(3)" :header="tableHeaders[3]">
          <template v-if="item.voucher">
            {{ item.voucher.customNumber }}
            <div class="subtitle-1 grey--text">
              {{ item.voucher.bnetId }}
            </div>
          </template>
          <template v-else>-</template>
        </app-table-data>
        <app-table-data :sorted="isSorted(4)" :header="tableHeaders[4]">
          <template v-if="item.address.street">{{ item.address.street }},</template>
          {{ item.address.zip }} {{ item.address.city }}
        </app-table-data>
        <app-table-data :sorted="isSorted(5)" :header="tableHeaders[5]">
          {{ item.creationDate | formatDate }}
        </app-table-data>

        <app-table-data>
          <app-btn
            v-if="item.isOpen"
            min-height="unset"
            class="py-1 px-2"
            outlined
            @click="setQueryParam(queryParam.ASSIGN_DEBTOR, item.id)"
          >
            {{ $t('edit') }}
          </app-btn>
        </app-table-data>
      </tr>
    </template>

    <template #no-data>
      <p>{{ $t('assignDebtors.noData') }}</p>
    </template>
  </app-data-table>
</template>

<script>
import TableMixin from '@/mixins/TableMixin';
import formatProduct from '@/helper/filter/formatProduct';
import { NAMESPACE } from '@/modules/Admin/store';
import { mapState } from 'vuex';

export default {
  name: 'AssignDebtorsTable',

  mixins: [TableMixin],

  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      tableHeaders: [
        { text: this.$t('assignDebtors.caseStatus'), value: 'statusTranslated', sortMobile: true },
        {
          text: this.$t('creditor'),
          subText: this.$t('product'),
          alignLeft: true,
          sortable: false
        },
        { text: this.$t('sapNumber'), value: 'sapNumber', sortMobile: true },
        {
          text: this.$t('vouchers.invoiceNumber'),
          subText: this.$t('bnetIdentifier'),
          sortable: false
        },
        {
          text: this.$t('address'),
          sortable: false
        },
        {
          text: this.$t('date'),
          value: 'creationDate',
          sortMobile: true
        },
        { text: this.$t('actions'), value: null, sortable: false }
      ]
    };
  },

  computed: {
    ...mapState(NAMESPACE, ['loadingList']),

    formattedTableData() {
      return JSON.parse(JSON.stringify(this.tableData)).map((item) => ({
        ...item,
        productTranslated: formatProduct(item.creditor.productType),
        statusTranslated: this.$t(`assignDebtors.status.${item.status}`)
      }));
    }
  }
};
</script>
