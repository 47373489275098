import TABLE_QUERY_DEFAULTS from '@/statics/tableQueryDefaults';

export default {
  data: () => ({
    lastQuery: null
  }),

  computed: {
    defaultQuery() {
      return {
        ...this.$route.query,
        [this.queryParam.PAGE]:
          this.getQueryParam(this.queryParam.PAGE) || TABLE_QUERY_DEFAULTS[this.queryParam.PAGE],
        [this.queryParam.ITEMS_PER_PAGE]:
          this.getQueryParam(this.queryParam.ITEMS_PER_PAGE) ||
          TABLE_QUERY_DEFAULTS[this.queryParam.ITEMS_PER_PAGE],
        [this.queryParam.SORT_BY]:
          this.getQueryParam(this.queryParam.SORT_BY) ||
          TABLE_QUERY_DEFAULTS[this.queryParam.SORT_BY],
        [this.queryParam.SORT_DESC]:
          this.getQueryParam(this.queryParam.SORT_DESC) ||
          TABLE_QUERY_DEFAULTS[this.queryParam.SORT_DESC],
        ...this.buildDefaultFilterQueries(),
        ...this.buildDefaultSortingQueries()
      };
    },
    bnetIdentifierSelection: {
      get() {
        return this.getFilterQueryParam('bnetIdentifier').map((bnetId) =>
          !isNaN(Number(bnetId)) ? Number(bnetId) : bnetId
        );
      },
      set(value) {
        this.setFilterQueryParam('bnetIdentifier', value);
      }
    },
    encashmentFileNumberSelection: {
      get() {
        return this.getFilterQueryParam('encashmentFileNumber');
      },
      set(value) {
        this.setFilterQueryParam('encashmentFileNumber', value);
      }
    },
    customNumberSelection: {
      get() {
        return this.getFilterQueryParam('customNumber');
      },
      set(value) {
        this.setFilterQueryParam('customNumber', value);
      }
    },
    debtorSelection: {
      get() {
        return this.getFilterQueryParam('debtor');
      },
      set(value) {
        this.setFilterQueryParam('debtor', value);
      }
    },
    dueDateSelection: {
      get() {
        return this.getFilterQueryParam('dueDate');
      },
      set(value) {
        this.setFilterQueryParam('dueDate', value);
      }
    },
    limitSelection: {
      get() {
        return this.getFilterQueryParam('limit');
      },
      set(value) {
        this.setFilterQueryParam('limit', value);
      }
    },
    locationSelection: {
      get() {
        return this.getFilterQueryParam('location');
      },
      set(value) {
        this.setFilterQueryParam('location', value);
      }
    },
    officialVoucherNumberSelection: {
      get() {
        return this.getFilterQueryParam('officialVoucherNumber');
      },
      set(value) {
        this.setFilterQueryParam('officialVoucherNumber', value);
      }
    },
    periodSelection: {
      get() {
        return this.getFilterQueryParam('period');
      },
      set(value) {
        this.setFilterQueryParam('period', value);
      }
    },
    purchaseStatusSelection: {
      get() {
        return this.getFilterQueryParam('purchaseStatus');
      },
      set(value) {
        this.setFilterQueryParam('purchaseStatus', value);
      }
    },
    referenceNumberSelection: {
      get() {
        return this.getFilterQueryParam('referenceNumber');
      },
      set(value) {
        this.setFilterQueryParam('referenceNumber', value);
      }
    },
    roleSelection: {
      get() {
        return this.getFilterQueryParam('role');
      },
      set(value) {
        this.setFilterQueryParam('role', value);
      }
    },
    sapNumberSelection: {
      get() {
        return this.getFilterQueryParam('sapNumber');
      },
      set(value) {
        this.setFilterQueryParam('sapNumber', value);
      }
    },
    readStatusSelection: {
      get() {
        return this.getFilterQueryParam('readStatus');
      },
      set(value) {
        this.setFilterQueryParam('readStatus', value);
      }
    },
    statusSelection: {
      get() {
        return this.getFilterQueryParam('status');
      },
      set(value) {
        this.setFilterQueryParam('status', value);
      }
    },
    subjectSelection: {
      get() {
        return this.getFilterQueryParam('subject');
      },
      set(value) {
        this.setFilterQueryParam('subject', value);
      }
    },
    typeSelection: {
      get() {
        return this.getFilterQueryParam('type');
      },
      set(value) {
        this.setFilterQueryParam('type', value);
      }
    },
    uploadDateSelection: {
      get() {
        return this.getFilterQueryParam('uploadDate');
      },
      set(value) {
        this.setFilterQueryParam('uploadDate', value);
      }
    },
    voucherDateSelection: {
      get() {
        return this.getFilterQueryParam('voucherDate');
      },
      set(value) {
        this.setFilterQueryParam('voucherDate', value);
      }
    },
    hasActiveFilters() {
      return (
        [].concat(
          this.bnetIdentifierSelection,
          this.encashmentFileNumberSelection,
          this.customNumberSelection,
          this.debtorSelection,
          this.dueDateSelection,
          this.limitSelection,
          this.locationSelection,
          this.officialVoucherNumberSelection,
          this.periodSelection,
          this.purchaseStatusSelection,
          this.referenceNumberSelection,
          this.roleSelection,
          this.sapNumberSelection,
          this.readStatusSelection,
          this.statusSelection,
          this.subjectSelection,
          this.typeSelection,
          this.uploadDateSelection,
          this.voucherDateSelection
        ).length > 0
      );
    }
  },

  methods: {
    fromEntries(iterable) {
      return [...iterable].reduce((obj, [key, val]) => {
        obj[key] = val;
        return obj;
      }, {});
    },

    resetAllFilter() {
      this.$router.push({
        query: {
          [this.queryParam.ITEMS_PER_PAGE]: this.getQueryParam(this.queryParam.ITEMS_PER_PAGE),
          [this.queryParam.SORT_BY]: this.getQueryParam(this.queryParam.SORT_BY),
          [this.queryParam.SORT_DESC]: this.getQueryParam(this.queryParam.SORT_DESC),
          [this.queryParam.PAGE]: 1,
          ...(this.getQueryParam(this.queryParam.TAB)
            ? { [this.queryParam.TAB]: this.getQueryParam(this.queryParam.TAB) }
            : {})
        }
      });
    },

    buildSorting(query) {
      if (
        Array.isArray(query[this.queryParam.SORT_BY]) &&
        query[this.queryParam.SORT_BY].length > 0 &&
        query[this.queryParam.SORT_BY][0]
      ) {
        return {
          [query[this.queryParam.SORT_BY][0]]:
            query[this.queryParam.SORT_DESC][0].toString() === 'true' ? 'DESC' : 'ASC'
        };
      }

      return undefined;
    },

    updateTableQueries(query, queryValues, loadFunction) {
      const queryFilter = Object.entries(query).filter(([param]) => queryValues.includes(param));
      const lastQueryFilter = Object.entries(this.lastQuery).filter(([param]) =>
        queryValues.includes(param)
      );

      if (
        JSON.stringify(this.fromEntries(queryFilter)) ===
        JSON.stringify(this.fromEntries(lastQueryFilter))
      ) {
        return;
      }

      if (
        this.lastQuery &&
        query[this.queryParam.PAGE] === this.lastQuery[this.queryParam.PAGE] &&
        Number(query[this.queryParam.PAGE]) > 1
      ) {
        this.setQueryParam(this.queryParam.PAGE, 1, true);
        return;
      }

      this.lastQuery = { ...query };
      loadFunction(query);
    },

    buildDefaultFilterQueries() {
      if (this.hasActiveFilters === true || !this.defaultFilter) {
        return;
      }

      return this.defaultFilter;
    },

    buildDefaultSortingQueries() {
      if (this.getQueryParam(this.queryParam.SORT_BY) || !this.defaultSorting) {
        return;
      }

      return {
        [this.queryParam.SORT_BY]: this.defaultSorting.sortBy,
        [this.queryParam.SORT_DESC]: this.defaultSorting.sortDesc
      };
    }
  },

  created() {
    this.setQueryParams(this.defaultQuery, true);
    this.lastQuery = { ...this.defaultQuery };
  }
};
