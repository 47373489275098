<template>
  <div class="assign-debtors__container">
    <div class="assign-debtors__area">
      <div class="assign-debtors__row grey-background">
        <template v-if="details.isDebtorRequest">
          <h5>
            {{ $t('assignDebtors.debtorRequest') }}
          </h5>
          <div class="assign-debtors__column assign-debtors__column--auto">
            <assign-debtors-field label="assignDebtors.normalizedAddress">
              {{ details.addresses.normalizedAddress.name }}
              <br />
              {{ details.addresses.normalizedAddress.street }}
              <br />
              {{ details.addresses.normalizedAddress.zip }}
              {{ details.addresses.normalizedAddress.city }}
            </assign-debtors-field>
            <assign-debtors-field label="assignDebtors.limitAddress">
              {{ details.addresses.limitAddress.name }}
              <br />
              {{ details.addresses.limitAddress.street }}
              <br />
              {{ details.addresses.limitAddress.zip }}
              {{ details.addresses.limitAddress.city }}
              <template v-if="details.addresses.limitAddress.email">
                <br />
                {{ $t('email') }}: {{ details.addresses.limitAddress.email }}
              </template>
              <template v-if="details.addresses.limitAddress.phone">
                <br />
                {{ $t('tel') }}: {{ details.addresses.limitAddress.phone }}
              </template>
            </assign-debtors-field>
          </div>
          <div class="assign-debtors__column">
            <assign-debtors-field
              label="date"
              :value="filters.formatDate(details.debtorRequestDate)"
            />
            <assign-debtors-field
              label="assignDebtors.manualDecisionReason"
              :value="
                $te(`assignDebtors.manualDecisionReasons.${details.manualDecisionReason}`)
                  ? $t(`assignDebtors.manualDecisionReasons.${details.manualDecisionReason}`)
                  : '-'
              "
            />
          </div>
        </template>
        <template v-else>
          <h5>
            {{ $t('invoice') }}
            <file-link-btn
              :loading="loadingFileType === 'originalVoucher'"
              append-icon
              @click="openFile(details.voucher.source, 'originalVoucher', details.voucher.id)"
            />
          </h5>
          <div class="assign-debtors__column assign-debtors__column--auto">
            <assign-debtors-field label="assignDebtors.invoiceAddress">
              {{ details.addresses.normalizedAddress.name }}
              <br />
              {{ details.addresses.normalizedAddress.street }}
              <br />
              {{ details.addresses.normalizedAddress.zip }}
              {{ details.addresses.normalizedAddress.city }}
            </assign-debtors-field>
            <assign-debtors-field label="assignDebtors.limitAddress">
              {{ details.addresses.limitAddress.name }}
              <br />
              {{ details.addresses.limitAddress.street }}
              <br />
              {{ details.addresses.limitAddress.zip }}
              {{ details.addresses.limitAddress.city }}
              <template v-if="details.addresses.limitAddress.email">
                <br />
                {{ $t('email') }}: {{ details.addresses.limitAddress.email }}
              </template>
              <template v-if="details.addresses.limitAddress.phone">
                <br />
                {{ $t('tel') }}: {{ details.addresses.limitAddress.phone }}
              </template>
            </assign-debtors-field>
          </div>
          <div class="assign-debtors__column">
            <assign-debtors-field
              label="status"
              :value="filters.formatVoucherStatusToShow(details.voucher.statusToShow)"
            />
            <assign-debtors-field
              label="assignDebtors.documentId"
              :value="details.voucher.documentId"
            />
            <assign-debtors-field
              label="assignDebtors.bnetId"
              :value="details.voucher.bnetId || '-'"
            />
          </div>
          <div class="assign-debtors__column">
            <assign-debtors-field
              label="vouchers.invoiceNumber"
              :value="details.voucher.customNumber || '-'"
            />
            <assign-debtors-field
              label="debtors.customerId"
              :value="details.voucher.customDebtorNumber || '-'"
            />
            <assign-debtors-field label="sapNumber" :value="details.voucher.sapNumber || '-'" />
          </div>

          <div class="assign-debtors__column">
            <assign-debtors-field
              label="amount"
              :value="filters.formatCurrency(details.voucher.amount)"
            />
            <assign-debtors-field
              label="vouchers.voucherDate"
              :value="filters.formatDate(details.voucher.voucherDate)"
            />
            <assign-debtors-field
              label="vouchers.maturity"
              :value="filters.formatDate(details.voucher.paymentDeadline)"
            />
          </div>

          <div class="assign-debtors__column">
            <assign-debtors-field label="email" :value="details.voucher.email || '-'" />
            <assign-debtors-field
              label="debtors.deliveryType"
              :value="
                $te(`addresses.deliveryTypes.${details.voucher.deliveryType}`)
                  ? $t(`addresses.deliveryTypes.${details.voucher.deliveryType}`)
                  : '-'
              "
            />
            <assign-debtors-field
              label="assignDebtors.manualDecisionReason"
              :value="
                $te(`assignDebtors.manualDecisionReasons.${details.manualDecisionReason}`)
                  ? $t(`assignDebtors.manualDecisionReasons.${details.manualDecisionReason}`)
                  : '-'
              "
            />
          </div>
        </template>
      </div>
    </div>

    <div class="assign-debtors__area">
      <div class="assign-debtors__row grey-background">
        <h5>
          {{ $t('creditor') }}
        </h5>
        <div class="assign-debtors__column">
          <assign-debtors-field label="product">
            {{ details.creditor.productType | formatProduct }}
          </assign-debtors-field>
          <assign-debtors-field
            label="creditors.contractDate"
            :value="filters.formatDate(details.creditor.contractStartDate)"
          />
          <assign-debtors-field
            label="assignDebtors.creationDate"
            :value="filters.formatDate(details.creditor.creationDate)"
          />
        </div>
        <div class="assign-debtors__column assign-debtors__column--auto">
          <assign-debtors-field label="sapNumber" :value="details.creditor.sapNumber" />
          <assign-debtors-field label="address">
            {{ details.creditor.contact.firstName }} {{ details.creditor.contact.lastName }}
            <br />
            {{ details.creditor.address.street }}
            <br />
            {{ details.creditor.address.zip }} {{ details.creditor.address.city }}
          </assign-debtors-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssignDebtorsField from '@/modules/Admin/components/AdminActions/AssignDebtors/AssignDebtorsField.vue';
import FileLinkBtn from '@/shared/components/FileLinkBtn.vue';
import OpenFileMixin from '@/mixins/OpenFileMixin';
import formatCurrency from '@/helper/filter/formatCurrency';
import formatDate from '@/helper/filter/formatDate';
import formatVoucherStatusToShow from '@/helper/filter/formatVoucherStatusToShow';

export default {
  name: 'AssignDebtorsBaseData',

  mixins: [OpenFileMixin],

  components: { FileLinkBtn, AssignDebtorsField },

  props: {
    details: {
      type: Object,
      required: true
    }
  },

  computed: {
    filters() {
      return {
        formatCurrency,
        formatDate,
        formatVoucherStatusToShow
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/modules/Admin/styles/assignDebtors.scss';

.assign-debtors__column {
  flex-basis: 150px;
  flex-grow: 1;
}
</style>
