<template>
  <v-chip
    :color="color"
    close-icon="mdi-close"
    outlined
    pill
    close
    class="filter-chip"
    @click:close="$emit('input', updatedValue)"
  >
    <component :is="`${icon}`" class="filter-chip__icon" />
    <slot></slot>
  </v-chip>
</template>

<script>
export default {
  name: 'ActiveFilterChip',

  props: {
    value: {
      type: [Array, String],
      default: () => []
    },
    color: {
      type: String,
      default: 'background-text'
    },
    icon: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: -1
    }
  },

  computed: {
    updatedValue() {
      let newValue = this.value.slice();
      newValue.splice(this.index, 1);

      return newValue;
    }
  }
};
</script>

<style scoped>
.filter-chip {
  /* also used in FilterWrapper.vue to overwrite default colors */
  margin-right: 16px;
  margin-block: 4px;
}

.filter-chip__icon {
  height: 17px;
  width: 17px;
  margin-right: 8px;
}
</style>
