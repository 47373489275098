<template>
  <div class="data__row">
    <span class="grey--text caption">{{ $t(label) }}</span>
    <slot>{{ value }}</slot>
  </div>
</template>

<script>
export default {
  name: 'AssignDebtorsField',

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped lang="scss">
.data__row {
  display: flex;
  flex-direction: column;
  word-break: break-word;
  hyphens: auto;
}
</style>
