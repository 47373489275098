export default {
  props: {
    value: {
      type: [Boolean, String],
      default: false
    }
  },

  data: () => ({
    fadingOut: false,
    submitted: false,
    isLoading: false,
    error: null
  }),

  computed: {
    loading: {
      get() {
        return this.$attrs.loading ? this.$attrs.loading : this.isLoading;
      },
      set(value) {
        this.isLoading = value;
      }
    },
    open: {
      get() {
        return !!this.value && !this.fadingOut && !this.submitted;
      },
      set() {
        this.fadingOut = true;
        setTimeout(() => {
          this.$emit('input', false);
          this.fadingOut = false;
        }, 200);
      }
    },

    openConfirmation: {
      get() {
        return this.value && this.submitted;
      },
      set() {
        this.$emit('input', false);
      }
    }
  },

  methods: {
    hideAfterSuccess() {
      this.submitted = false;
      this.error = null;
      this.$emit('success');
      this.$emit('input', false);
    },

    close() {
      this.error = null;
      this.$emit('input', false);
    }
  }
};
