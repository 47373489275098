<template>
  <v-container fluid class="pa-0">
    <v-expansion-panels v-if="isMobile" accordion flat tile>
      <v-expansion-panel class="pa-2">
        <v-expansion-panel-header class="pa-0 body-1 text-uppercase">
          <v-row align="baseline" no-gutters>
            <v-col cols="auto">
              <v-icon class="flex-auto mr-2">mdi-filter</v-icon>
              {{ $t('listFilter') }}
            </v-col>
            <v-col cols="auto">
              <app-text-btn
                v-if="hasActiveFilters && isMobile"
                color="white"
                class="caption text-uppercase text--underlined ml-4"
                title="resetFilter"
                @click.stop="$emit('reset')"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <slot></slot>
        </v-expansion-panel-content>

        <slot name="active-filters"></slot>
      </v-expansion-panel>
    </v-expansion-panels>

    <div v-else class="mt-4">
      <slot></slot>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'FilterWrapper',

  props: {
    hasActiveFilters: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.v-expansion-panels .v-expansion-panel {
  background-color: var(--c-text);
  color: var(--c-white);

  ::v-deep .filter-chip {
    color: var(--c-white) !important;
    border-color: var(--c-white) !important;
  }
}

.theme--light.v-expansion-panels .v-expansion-panel-header {
  i,
  ::v-deep .v-expansion-panel-header__icon i {
    color: var(--c-white);
  }
}
</style>
