<template>
  <app-select
    filter
    :items="states"
    :label="$t('assignDebtors.caseStatus')"
    multiple
    hide-details
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import DebtorAssignmentStatus from '@/modules/Admin/statics/debtorAssignmentStatus';

export default {
  name: 'StatusSelect',

  computed: {
    states() {
      return Object.values(DebtorAssignmentStatus).map((status) => ({
        label: this.$t(`assignDebtors.status.${status}`),
        value: status
      }));
    }
  }
};
</script>
