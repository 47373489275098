<template>
  <filter-wrapper :has-active-filters="hasActiveFilters" @reset="resetAllFilter">
    <v-row dense>
      <v-col cols="12" sm="4" md="3" lg="2">
        <status-select v-model="statusSelection" />
      </v-col>
    </v-row>

    <template :slot="isMobile ? 'active-filters' : 'default'">
      <v-row v-if="!isMobile || hasActiveFilters" dense>
        <v-col cols="12" class="pt-3 pb-4">
          <active-filter-chip
            v-for="(selection, index) in statusSelection"
            :key="selection"
            v-model="statusSelection"
            :index="index"
          >
            {{ $t(`assignDebtors.status.${selection}`) }}
          </active-filter-chip>

          <app-text-btn
            v-if="hasActiveFilters"
            color="background-text"
            class="caption"
            title="resetFilter"
            @click="resetAllFilter"
          />
        </v-col>
      </v-row>
    </template>
  </filter-wrapper>
</template>

<script>
import ActiveFilterChip from '@/shared/components/ActiveFilterChip';
import FilterWrapper from '@/shared/components/FilterWrapper';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import StatusSelect from '@/modules/Admin/components/AdminActions/AssignDebtors/StatusSelect';

export default {
  name: 'AssignDebtorsFilter',

  mixins: [ListFilterMixin],

  components: {
    ActiveFilterChip,
    FilterWrapper,
    StatusSelect
  }
};
</script>
