<template>
  <v-container fluid pa-0>
    <assign-debtors-filter />

    <assign-debtors-table :table-data="debtorAssignmentList" :count="debtorAssignmentListCount" />

    <assign-debtors-dialog
      v-model="selectedEntryId"
      :entry-id="selectedEntryId"
      @success="loadList"
    />
  </v-container>
</template>

<script>
import AssignDebtorsDialog from '@/modules/Admin/components/AdminActions/AssignDebtors/AssignDebtorsDialog';
import AssignDebtorsFilter from '@/modules/Admin/components/AdminActions/AssignDebtors/AssignDebtorsFilter';
import AssignDebtorsTable from '@/modules/Admin/components/AdminActions/AssignDebtors/AssignDebtorsTable';
import DebtorAssignmentStatus from '@/modules/Admin/statics/debtorAssignmentStatus';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import { NAMESPACE } from '@/modules/Admin/store';
import { QueryParam } from '@/statics/queryParam';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AssignDebtors',

  components: {
    AssignDebtorsTable,
    AssignDebtorsFilter,
    AssignDebtorsDialog
  },

  mixins: [ListFilterMixin],

  data: () => ({
    filteredTableData: [],
    defaultSorting: {
      sortBy: ['creationDate'],
      sortDesc: [true]
    },
    defaultFilter: {
      status: [DebtorAssignmentStatus.OPEN]
    },
    queryValues: [
      QueryParam.PAGE,
      QueryParam.ITEMS_PER_PAGE,
      QueryParam.SORT_BY,
      QueryParam.SORT_DESC,
      'status'
    ]
  }),

  watch: {
    '$route.query': {
      deep: true,
      handler(query) {
        this.updateTableQueries(query, this.queryValues, this.loadList);
      }
    }
  },

  computed: {
    ...mapState(NAMESPACE, ['debtorAssignmentList', 'debtorAssignmentListCount', 'loadingList']),
    selectedEntryId: {
      get() {
        return this.getQueryParam(this.queryParam.ASSIGN_DEBTOR);
      },
      set(value) {
        return this.setQueryParam(this.queryParam.ASSIGN_DEBTOR, value);
      }
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchDebtorAssignmentList']),

    loadList(query = this.defaultQuery) {
      this.fetchDebtorAssignmentList({
        page: query[QueryParam.PAGE],
        rowsPerPage: query[QueryParam.ITEMS_PER_PAGE],
        filters: this.buildFilters(),
        sorting: this.buildSorting(query)
      });
    },

    buildFilters() {
      let filters = {};

      if (this.statusSelection.length > 0) {
        filters.status = this.statusSelection;
      }

      return Object.keys(filters).length > 0 ? filters : undefined;
    }
  },

  created() {
    this.loadList();
  }
};
</script>
